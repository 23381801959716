<template>
  <div>
    <v-card class="mb-8">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="$store.state.campaignUser.field.user"
              label="Rechercher globalement"
              outlined
              dense
              placeholder="Rechercher globalement"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['campaignUser/reduct_code_users']"
        :items-per-page="$store.getters['campaignUser/per_page']"
        :page.sync="$store.getters['campaignUser/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>
        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :remove="false"
            :edit="false"
            :lock="false"
            :status="false"
            @view="openDetails(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.campaignUser.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getListPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getList"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { formatDate, formatDateWithoutHour } from '@core/date/dateFunctions'
import {
  mdiAccountLock, mdiCashMinus, mdiCashPlus,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiRefresh,
  mdiSquareEditOutline,
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import ButtonsGroup from '@core/components/buttons/ButtonsGroup'
import store from '@/store'

export default {

  name: 'CampaignUsersList',
  components: { ButtonsGroup, LoaderDialog },
  props: {
    rec_code_id: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const message = ref(null)
    const menuref = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const isDialogVisible = ref(false)
    const isAlertVisible = ref(false)
    const error = ref(false)
    const { router } = useRouter()

    const save = d => {
      menuref.value.save(d)
    }

    const getList = (page = 1) => {
      isDialogVisible.value = true
      store.state.campaignUser.field.reduct_code_id = props.rec_code_id
      store.dispatch('campaignUser/getCampaignUsersList', { page, field: store.state.campaignUser.field }).then(() => {
        currentPage.value = store.getters['campaignUser/current_page']
        lastPage.value = store.getters['campaignUser/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
      }).catch(err => {
        message.value = err.response.data.message
        error.value = true
        isAlertVisible.value = true
      })
    }

    const getListPerPage = per_page => {
      isDialogVisible.value = true
      store.state.campaignUser.field.paginate = per_page
      store.dispatch('campaignUser/getCampaignUsersList', { field: store.state.campaignUser.field }).then(() => {
        currentPage.value = store.getters['campaignUser/current_page']
        lastPage.value = store.getters['campaignUser/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const filterItems = () => {
      setTimeout(() => {
        getList()
      }, 1500)
    }

    const formattingDate = date => formatDate(date)

    const formattingDateExHour = date => formatDateWithoutHour(date)

    const openDetails = item => {
      router.push({ name: 'showuser', params: { id: item.user.id } })
    }

    onMounted(() => {
      setTimeout(() => {
        getList(store.getters['campaignUser/current_page'])
      }, 1000)
    })

    return {
      getList,
      openDetails,
      formattingDate,
      formattingDateExHour,
      filterItems,
      getListPerPage,
      save,
      date,
      currentPage,
      lastPage,
      message,
      isAlertVisible,
      isDialogVisible,
      error,
      menu,
      picker,
      menuref,
      responses: [
        { text: 'OUI', value: true },
        { text: 'NON', value: false },
      ],
      headers: [
        { text: 'NOM', value: 'user.name' },
        { text: 'EMAIL', value: 'user.email' },
        { text: 'TEL', value: 'user.phone_number' },
        { text: 'DATE CONSOMMATION', value: 'created_at' },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      itemsPerPage: parseInt(store.state.roadPayment.field.paginate, 10),

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiCashMinus,
        mdiCashPlus,
      },
    }
  },

}
</script>

<style scoped>

</style>
