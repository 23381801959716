<template>
  <div>
    <reduct-code-card
      :object="$store.state.campaign.campaign"
      class="mb-4"
    />
    <reduct-code-owner
      :user="$store.state.campaign.campaign.user"
      title="Propriétaire"
      subtitle="Propriétaire du code"
    />
    <reduct-code-owner
      :user="$store.state.campaign.campaign.admin"
      title="Publié par"
      subtitle="Code réduction enregistré par"
    />
  </div>
</template>

<script>
import CampaignCard from '@core/components/campaign/CampaignCard'
import CampaignOwner from '@core/components/campaign/CampaignOwner'

export default {
  name: 'CampaignPanel',
  components: { CampaignOwner, CampaignCard },
  setup() {

  },
}
</script>

<style scoped>

</style>
